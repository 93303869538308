<template>
  <div class="">
    <!-- Header Start -->
    <header id="home" class="home-banner-area home-banner-area-2 position-relative">
      <!-- Video Background -->
      <div class="video-container">
        <video autoplay muted loop playsinline :src="particleVideo" class="video-bg"></video>
        <!-- Gradient Overlay -->
        <div class="overlay"></div>
      </div>
      <!-- Content Overlay -->
      <div class="container content-overlay">
        
          <div class="col-12">
            <!--Hero Area Wrapper-->
            <div class="hero-area-wrapper position-relative">
              <div class="hero-container">
                <div class="hero-area-content">
                  <h1>{{ heroTitle }}</h1>
                  <p>{{ heroSubTitle }}</p>
                  <div class="hero-button-box">
                    <!-- Buttons, if needed -->
                    <!-- <a href="https://play.google.com/store/apps" class="theme-button"><i class="fab fa-google-play"></i>Play Store</a> -->
                    <!-- <a href="https://apps.apple.com/us/app/apple-store/id375380948" class="theme-button"><i class="fab fa-apple"></i>App Store</a> -->
                  </div>
                </div>
              </div>
            </div>
            <!--Hero Area Wrapper-->
          
        </div>
      </div>
    </header>
    <!-- Header End -->
  </div>
</template>

<script>
export default {
  name: 'Banner2',

  data() {
    return {
      heroTitle: 'Future of Sports',
      heroSubTitle: 'Get ready to revolutionize your sports experience with Instaplay! Book your next game now and experience the convenience and excitement of Instaplay. Launching soon, stay tuned!',
      particleVideo: require('../../assets/video/background.mp4'), // Video path
    }
  }
}
</script>

<style scoped>
/* Ensure the video covers the full header area */
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2; /* Keep video behind the content and overlay */
  overflow: hidden;
}

/* Video background */
.video-bg {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video covers the entire area */
}

/* Gradient Orange overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(249, 158, 35, 0.2), rgba(249, 158, 35, 0)); /* Gradient from 40% opacity to 0% */
  z-index: 0; /* Keep overlay above the video but behind the content */
}

.content-overlay {
  position: relative;
  z-index: 1; /* Content on top of the overlay */
}

.home-banner-area-2.home-banner-area {
  background: none !important; /* Remove the background image */
  position: relative;
  overflow: hidden;
}

.hero-area-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.hero-area-content h1 {
  font-size: 5rem;
  color: #fff;
  font-weight: bold;
}

.hero-area-content p {
  font-size: 1.3rem;
  color: #ddd;
  font-weight: 700;
}
</style>
