<template>
  <div class="promo-banner" ref="promoBanner">
    <div class="button-container">
      <a href="#" class="store-button app-store">
        <img
          src="../../assets/img/apple_logo.png"
          alt="App Store"
          class="store-logo"
        />
        <span>
          <span class="line-one">Download on the</span>
          <span class="line-two">App Store</span>
        </span>
      </a>
      <a href="#" class="store-button google-play">
        <img
          src="../../assets/img/play-store-logo.png"
          alt="Google Play"
          class="store-logo"
        />
        <span>
          <span class="line-one">Get it now on</span>
          <span class="line-two">Google Play</span>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadNow",
};
</script>

<style scoped>
/* Promo Banner Style */
.promo-banner {
  background-color: rgb(255, 175, 70); /* Background color */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
  color: white;
  width: 100%; 
  height: 1%; 
  opacity: 1; /* Initially hidden */
  transition: width 0.3s ease, opacity 0.3s ease; /* Smooth transition for width and opacity */
}

/* Freeze Scroll Effect */
.freeze-scroll {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden; /* Prevents body from scrolling */
  z-index: 999; /* Should be below the banner */
}

/* Button Container Style */
.button-container {
  display: flex;
  gap: 20px; /* Space between buttons */
}

/* Store Button Style */
.store-button {
  position: relative; /* Ensure ripple effect is positioned correctly */
  display: flex;
  align-items: center;
  background-color: black;
  border: 2px solid white;
  border-radius: 15px;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

/* Store Button Hover Style */
.store-button:hover {
  background-color: rgba(165, 165, 165, 0.5);
}

/* Store Logo Style */
.store-logo {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

/* Google Play Text Styling */
.line-one {
  display: block;
  font-size: 12px; /* Size for 'Get it now' */
  font-weight: bold;
  text-align: center;
}

.line-two {
  display: block;
  font-size: 18px; /* Size for 'Get it on Google Play' */
  text-align: center;
}
</style>
